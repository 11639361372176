import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import moment from "moment";
import googleIcon from "../images/icons/google.svg";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Stack } from "react-bootstrap";
import Ratings from "react-star-ratings";

const ReviewCard = ({ review, black }) => {
	var trimmedString = review.reviewBody.substr(0, 145);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		// console.log("clicked");
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<div
			className="p-3 h-100"
			style={{
				backgroundColor: "#181819",
				borderRadius: 12,
			}}
		>
			<Stack direction="horizontal" gap={3}>
				<img
					style={{ width: 34, height: 34 }}
					src={googleIcon}
					alt="google icon"
				/>

				<Stack direction="horizontal" gap={3}>
					<Ratings
						rating={5}
						starRatedColor="rgba(252,213,3,1.0)"
						svgIconPath="M7.3604 1.57639C7.55309 1.18857 8.10632 1.18857 8.29901 1.57639L9.87269 4.74368C9.94899 4.89726 10.0956 5.00378 10.2653 5.02889L13.7638 5.54681C14.1922 5.61022 14.3632 6.13638 14.0539 6.43948L11.5279 8.91488C11.4054 9.03491 11.3494 9.20727 11.3779 9.37637L11.9665 12.8638C12.0386 13.2908 11.591 13.6159 11.2071 13.4155L8.07232 11.778C7.92032 11.6986 7.73909 11.6986 7.58708 11.778L4.45226 13.4155C4.06842 13.6159 3.62085 13.2908 3.69291 12.8638L4.28147 9.37637C4.31 9.20727 4.254 9.03491 4.13152 8.91488L1.60554 6.43948C1.29625 6.13638 1.4672 5.61022 1.89558 5.54681L5.39415 5.02889C5.56379 5.00378 5.71041 4.89726 5.78672 4.74368L7.3604 1.57639Z"
						svgIconViewBox="0 0 16 16"
						starDimension="16px"
						starSpacing="0"
						numberOfStars={5}
						name="rating"
					/>
				</Stack>
			</Stack>
			<Row className="pt-3">
				<Col>
					<p
						className="d-md-none text-white pb-0 mb-1"
						style={{ fontSize: "70%" }}
					>
						{review.reviewBody.length > 145 && <div>{reviewBody}</div>}
						{review.reviewBody.length <= 145 && <div>{review.reviewBody}</div>}
					</p>
					<p
						className="d-none d-md-block text-white pb-0 mb-1"
						style={{ fontSize: "90%" }}
					>
						{review.reviewBody.length > 145 && <div>{reviewBody}</div>}
						{review.reviewBody.length <= 145 && <div>{review.reviewBody}</div>}
					</p>
				</Col>
			</Row>
			{review.reviewBody.length > 145 && (
				<Row className=" d-md-none pb-3">
					<Col>
						<p
							style={{ fontSize: "90%", cursor: "pointer" }}
							className="white-link inter-bold pointer pb-0 mb-2"
							onClick={handleClick}
						>
							{linkText}
						</p>
					</Col>
				</Row>
			)}

			{review.reviewBody.length > 145 && (
				<Row className=" d-none d-md-flex  ">
					<Col>
						<p
							style={{ cursor: "pointer" }}
							className="white-link inter-bold pointer pb-0 mb-2"
							onClick={handleClick}
						>
							{linkText}
						</p>
					</Col>
				</Row>
			)}
			<p
				style={{ fontSize: "90%" }}
				className="fst-italic text-white pb-0 mb-2"
			>
				from {review.author}
			</p>
			<a
				target="_blank"
				rel="noreferrer"
				href="https://www.google.com/maps/place/Ninja+Arena,+Eastbourne/@50.769601,0.28568,14z/data=!4m8!3m7!1s0x47df7341975be679:0x63c07fe02c3335f3!8m2!3d50.7696006!4d0.2856804!9m1!1b1!16s%2Fg%2F11t5y526t4?hl=en&entry=ttu"
				className="blue-link text-decoration-none"
			>
				Read on Google
			</a>
		</div>
	);
};

export default ReviewCard;
